/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          e.preventDefault();
        });

         $('[data-toggle="tooltip"]').tooltip();

        $("#promotionCarousel").slick({
   
            infinite: true,
            arrows: true,
            prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-circle-left'></span></a>",
            nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-circle-right'></span></a>",
            dots: true,
            
        });


        $("#upcomingShowsCarousel").slick({
   
            infinite: false,
            arrows: true,
            prevArrow: "<a href='#' class='slick-prev-small'><span class='fa fa-caret-left'></span></a>",
            nextArrow: "<a href='#' class='slick-next-small'><span class='fa fa-caret-right'></span></a>",
            swipe: false,
           
            
        });


        $(".show-pwd").on("click", function(e){

          e.preventDefault();

          $(".show-pwd").toggleClass("show-password");

          if ($(this).hasClass('show-password')) {
            $("#logPwd, #logPwd2").attr("type", "text");
            $(".show-pwd").html("<span class='fa fa-eye-slash'></span> Hide");
            
          } 
          else {
            $("#logPwd, #logPwd2").attr("type", "password");
            $(".show-pwd").html("<span class='fa fa-eye'></span> Show");
          }
        });




        $(".btn-subscribe-newsletter").closest("form").on("submit", function(e){
            e.preventDefault();
    
            
            var subBtn = $(".btn-subscribe-newsletter");
           
            var formURL = $(this).closest("form").attr("action");
            var emailAddress = $("#emailInput").val();


            subBtn.html("<span class='fa fa-spinner fa-spin'></span>");
            subBtn.attr("disabled", true);


            $("#emailInput").parent().attr("style", "position: relative;");


             $.ajax({
               url: formURL,
                method: 'post',
                data: {email_address: emailAddress},
                success: function(response){
                  console.log(response);
                  
                  var email = JSON.parse(response);

                  if(email.status === 'success'){
                      $("#emailInput").parent().append("<p class='alert alert-success' style='position: absolute; width: 100%; max-width: 650px;'><span class='fa fa-check-circle'></span> "+email.message+"</p>");
                      $(".alert").delay(1500).fadeOut(400);
                      
                      subBtn.html("<span class='fa fa-check-circle text-success'></span> Subscribed");
                  }

                  if(email.status === 'error'){
                      $("#emailInput").parent().append("<p class='alert alert-warning' style='position: absolute; width: 100%; max-width: 650px;'><span class='fa fa-exclamation-triangle'></span> "+email.message+"</p>");

                       $(".alert").delay(1500).fadeOut(400);

                      subBtn.text("Join The List");
                      subBtn.attr("disabled", false);
                  }


                  
                   

                },
                error: function(response){
                    console.log(response);
                     $("#emailInput").parent().append("<p class='alert alert-danger' style='position: absolute; width: 100%; max-width: 650px;'><span class='fa fa-exclamation-triangle'></span> There was an error processing your request. Try again.</p>");

                      $(".alert").delay(1500).fadeOut(400);

                      subBtn.text("Join The List");
                      subBtn.attr("disabled", false);
                }
            });
        });
      }
    },
    // Home page
    'sign_up': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $("#logPwd").on('keyup', function() {
          var number = /([0-9])/;
          var alphabets = /([A-Z])/;
          var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
          var password = $('#logPwd').val().trim();

            if (password.length >= 8) {
              $("#pwdLengthIcon").removeClass("fa-times");
              $("#pwdLengthIcon").removeClass("text-danger");
              $("#pwdLengthIcon").addClass("fa-check-circle");
              $("#pwdLengthIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }
         
            if (password.match(number)) {
              $("#pwdNumIcon").removeClass("fa-times");
              $("#pwdNumIcon").removeClass("text-danger");
              $("#pwdNumIcon").addClass("fa-check-circle");
              $("#pwdNumIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }

            if (password.match(special_characters)) {
              $("#pwdCharIcon").removeClass("fa-times");
              $("#pwdCharIcon").removeClass("text-danger");
              $("#pwdCharIcon").addClass("fa-check-circle");
              $("#pwdCharIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }

            if (password.match(alphabets) ) {
              
              $("#pwdAlphaIcon").removeClass("fa-times");
              $("#pwdAlphaIcon").removeClass("text-danger");
              $("#pwdAlphaIcon").addClass("fa-check-circle");
              $("#pwdAlphaIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");

            }else{
                $("#logPwd").removeClass("valid-pwd");
            }
        
      });


        $("#logPwd2").on('keyup', function() {
          var originalPassword = $('#logPwd').val().trim();
          var password = $('#logPwd2').val().trim();

          

            if (password === originalPassword) {
              $("#logPwdMatch").hide();

            }else{
                $("#logPwd2").removeClass("valid-pwd");
                $("#logPwdMatch").show();
            }
      });
     }
    },
    // About us page, note the change from about-us to about_us.
    'reset_password': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $("#logPwd").on('keyup', function() {
          var number = /([0-9])/;
          var alphabets = /([A-Z])/;
          var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
          var password = $('#logPwd').val().trim();

            if (password.length >= 8) {
              $("#pwdLengthIcon").removeClass("fa-times");
              $("#pwdLengthIcon").removeClass("text-danger");
              $("#pwdLengthIcon").addClass("fa-check-circle");
              $("#pwdLengthIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }
         
            if (password.match(number)) {
              $("#pwdNumIcon").removeClass("fa-times");
              $("#pwdNumIcon").removeClass("text-danger");
              $("#pwdNumIcon").addClass("fa-check-circle");
              $("#pwdNumIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }

            if (password.match(special_characters)) {
              $("#pwdCharIcon").removeClass("fa-times");
              $("#pwdCharIcon").removeClass("text-danger");
              $("#pwdCharIcon").addClass("fa-check-circle");
              $("#pwdCharIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }

            if (password.match(alphabets) ) {
              
              $("#pwdAlphaIcon").removeClass("fa-times");
              $("#pwdAlphaIcon").removeClass("text-danger");
              $("#pwdAlphaIcon").addClass("fa-check-circle");
              $("#pwdAlphaIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");

            }else{
                $("#logPwd").removeClass("valid-pwd");
            }
        
      });

        $("#logPwd2").on('keyup', function() {
          var originalPassword = $('#logPwd').val().trim();
          var password = $('#logPwd2').val().trim();

          

            if (password === originalPassword) {
              $("#logPwdMatch").hide();
              
            }else{
                $("#logPwd2").removeClass("valid-pwd");
                $("#logPwdMatch").show();
            }
      });

      }
    },
    'profile': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $("#logPwd").on('keyup', function() {
          var number = /([0-9])/;
          var alphabets = /([A-Z])/;
          var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
          var password = $('#logPwd').val().trim();

            if (password.length >= 8) {
              $("#pwdLengthIcon").removeClass("fa-times");
              $("#pwdLengthIcon").removeClass("text-danger");
              $("#pwdLengthIcon").addClass("fa-check-circle");
              $("#pwdLengthIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }
         
            if (password.match(number)) {
              $("#pwdNumIcon").removeClass("fa-times");
              $("#pwdNumIcon").removeClass("text-danger");
              $("#pwdNumIcon").addClass("fa-check-circle");
              $("#pwdNumIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }

            if (password.match(special_characters)) {
              $("#pwdCharIcon").removeClass("fa-times");
              $("#pwdCharIcon").removeClass("text-danger");
              $("#pwdCharIcon").addClass("fa-check-circle");
              $("#pwdCharIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");
            }else{
                $("#logPwd").removeClass("valid-pwd");
            }

            if (password.match(alphabets) ) {
              
              $("#pwdAlphaIcon").removeClass("fa-times");
              $("#pwdAlphaIcon").removeClass("text-danger");
              $("#pwdAlphaIcon").addClass("fa-check-circle");
              $("#pwdAlphaIcon").addClass("text-success");
              $("#logPwd").addClass("valid-pwd");

            }else{
                $("#logPwd").removeClass("valid-pwd");
            }
        
      });


        $("#logPwd2").on('keyup', function() {
          var originalPassword = $('#logPwd').val().trim();
          var password = $('#logPwd2').val().trim();

          

            if (password === originalPassword) {
              $("#logPwdMatch").hide();
              
            }else{
                $("#logPwd2").removeClass("valid-pwd");
                $("#logPwdMatch").show();
            }
      }); 

      }
    },

    'single_shows': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var ticketCost = 0;
        var ticketNum = 0;

        $("#ticketType").on("change", function(e){
          ticketCost = $(this).find(":selected").data("cost");

          var ticketQuantity = $(this).find(":selected").data("qty");


          if(ticketQuantity <= 10){
              $("#numTickets").html("<option value=''>Select Number of Tickets</option>");

              for(var i = 1; i <= ticketQuantity; i++)
              {
                $("#numTickets").append("<option value='"+i+"'>"+i+"</option>");
              }
          }
        });


        $("#numTickets").on("change", function(e){
          ticketNum = $(this).val();
        });


        $("#ticketForm select").on("change", function(e){
          var subtotal = (ticketCost * ticketNum).toFixed(2);

          if(ticketNum !== 0 && ticketCost !== 0){
             $("#subtotalPrice").text("£"+subtotal);
             $(".btn-checkout").attr("disabled", false);
          }
         
        });

      }
    },

    'my_account': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".redownload-ticket").on("click", function(e){

          
          var existingHTML = $(this).html();
          var redownloadButton = $(this);

          var downloadForm = $(this).closest("form");

          $(this).attr("disabled", true);

          $(this).text("Preparing download...");
          
          downloadForm[0].submit();

          setTimeout(function() { 

              redownloadButton.html(existingHTML);
              redownloadButton.attr("disabled", false);
              
          }, 7000);
        });

      }
    },

    'checkout': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".coupon-validate").on("click", function(e){
            e.preventDefault();

            $(this).html("<span class='fa fa-spinner'></span>");
            $(this).attr("disabled", true);
            

            var formURL = $(this).data("validation-url");
            var couponCode = $("#couponCode").val();
            var preDiscount = parseFloat($("#preDiscount").val(), 3);
            var showID = $("#sid").val();
           



             $.ajax({
               url: formURL,
                method: 'post',
                data: {coupon_code: couponCode, show_id: showID},
                success: function(response){
                  console.log(response);
                   $("#errorCoupon").hide();
                  var coupon = JSON.parse(response);

                    if(coupon.status === 'success'){
                      var couponValue = coupon.value;
                      var couponType = coupon.type;

                      if(couponType === 'percent'){

                          var totalDiscount = (preDiscount * (parseFloat(couponValue, 3) / 100)).toFixed(2);
                          var totalAfterDiscount = ((preDiscount * 1.035) - totalDiscount);
                          $(".total-td").text("£"+totalAfterDiscount.toFixed(2));
                          $("#totalP").val(totalAfterDiscount.toFixed(2));
                          $("#totalRow").before("<tr><th colspan='2'><span class='fa fa-check-circle text-success'></span> Discount Applied ("+couponValue+"%)</th><td align='right'>£"+totalDiscount+"</td></tr>");
                      }

                      else {

                          var totalAfterDiscount = ((preDiscount*1.035) - couponValue);

                          $("#totalRow").before("<tr><th colspan='2'><span class='fa fa-check-circle text-success'></span> Discount Applied</th><td align='right'>£"+couponValue+"</td></tr>");

                          $(".total-td").text("£"+totalAfterDiscount.toFixed(2));
                          $("#totalP").val(totalAfterDiscount.toFixed(2));
                      }

                      $(".coupon-validate").html("Applied!");

                       var checkoutForm = document.getElementById("checkoutForm");

                       var hiddenInput = document.createElement('input');
                      hiddenInput.setAttribute('type', 'hidden');
                      hiddenInput.setAttribute('name', 'coupon_code');
                      hiddenInput.setAttribute('value', couponCode);
                      checkoutForm.appendChild(hiddenInput);


                    }else{

                      $("#errorCoupon").show();
                      $("#errorCoupon").html("<th colspan='3'><p class='alert alert-warning'><span class='fa fa-exclamation-triangle'></span>"+coupon.message+"</p></th>");
                      $(".coupon-validate").attr("disabled", false);
                      $(".coupon-validate").html("Apply Promo");
                    }
                  
                   

                },
                error: function(response){
                    console.log(response);
                    $(".coupon-validate").html("Apply Promo");
                    $(".coupon-validate").attr("disabled", false);
                    $("#couponCode").attr("readonly", false);
                    $("#errorCoupon").html("<th colspan='3'><p class='alert alert-warning'><span class='fa fa-exclamation-triangle'></span> Error! Try again.</p></th>");

                }
            });
        });

      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $(".alert").delay(3000).fadeOut(340);

     }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
